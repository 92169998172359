<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn
          v-if="helperService.userHasPermission('fournisseurcontact_oadd')"
          class="action-btn"
          icon="create"
          color="primary"
          text="Ajouter un contact"
          :to="{ name: 'supplierContactAdd', params: { fId: supplierId, action: 'add' } }"
        />
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{ name: 'suppliers' }"
        />
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>

    <div class="view-body">
      <Section class="section section--list">
        <List
          :api="list.api"
          :header="list.headers"
          :items="list.col"
          :item-key="list.key"
          :target="list.target"
          @send-data="setData"
        >
          <template v-slot:telephoneportable="{ item }">
            <!-- TODO Nico: Icône du téléphone mobile -->
            {{ item.telephoneportable }}
          </template>

          <template v-slot:group-actions="{ selectedItems }">
            <Btn
              v-if="helperService.userHasPermission('fournisseurcontact_oremove')"
              text="Supprimer"
              color="default"
              @click="openModal('deleteItems', selectedItems)"
            />
          </template>

          <template v-slot:actions="{ item }">
            <Btn
              v-if="helperService.userHasPermission('fournisseurcontact_oedit')"
              default
              :to="{
                name: 'supplierContactEdit',
                params: { fId: supplierId, cId: item.id, action: 'edit' },
              }"
              icon="create"
              round
              grow
            />
            <Btn
              default
              v-if="helperService.userHasPermission('fournisseurcontact_oshow')"
              icon="visibility"
              :to="{ name: 'supplierContact', params: { fId: supplierId, cId: item.id } }"
              round
              grow
            />
          </template>
        </List>
      </Section>
      <Modal
        title="Supprimer des profils&nbsp;?"
        :active="modal.deleteItems"
        :data="modalData"
        @modal-close="modal.deleteItems = false"
      >
        <template v-slot:modal-body="{ data }">
          <p>
            Voulez vous vraiment supprimer
            {{ data.length }} fournisseurs&nbsp;?
          </p>
        </template>
        <template v-slot:modal-footer="{ data }">
          <Btn text="Annuler" @click="modal.deleteItems = false" />
          <Btn
            text="Supprimer"
            @click="deleteItems(data)"
            icon="trash-alt"
            color="primary"
          />
        </template>
      </Modal>

    </div>
  </div>
</template>

<script>
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Modal from '@/components/layout/Modal.vue'

export default {
  name: 'SuppliersView',

  components: {
    List,
    Pagination,
    Btn,
    Section,
    Modal,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      supplierId: this.$route.params.fId,
      list: {
        target: {
          route: {
            name: 'supplierContact',
            paramsFromRoute: { fId: this.$route.params.fId },
            itemParams: { cId: 'id' },
          },
        },
        api: {
          endpoint: `fournisseur/${this.$route.params.fId}/contact`,
          params: {
            sort: 'nom.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Nom', dataField: 'nom', isLink: true },
          { label: 'Mobile' },
          { label: 'Email' },
        ],
        col: ['nom', 'telephoneportable', 'email'],
        key: 'id',
      },
      data: [],
      modalData: {},
      modal: {
        deleteItems: false,
      },
    }
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },
    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        this.fetchService.delete(`fournisseur/${item.id}`).then(
          () => {
            this.emitter.emit('alert', {
              type: 'success',
              content: `Le fournisseur ${item.nom} a bien été supprimé.`,
            })
          },
          (responseError) => {
            this.emitter.emit('alert', {
              type: 'error',
              content: responseError.data,
            })
          },
        )
      })

      this.emitter.emit('list-refresh')
      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },

    setData(data) {
      this.data = data
    },
  },
}
</script>

<style lang="scss" scoped>
.role-profile {
  padding: $gutter-half;
}

.list-row {
  border: 1px solid grey;
}
</style>
